import {
  ViewContainerRef,
  ComponentRef,
  DestroyRef,
  Component,
  inject,
  OnInit
} from '@angular/core'
import { BehaviorSubject, debounceTime, Observable } from 'rxjs'
import { takeUntilDestroyed } from '@angular/core/rxjs-interop'

import { SelectComponent } from '@satinel-system/form'

import { DropdownService } from '@goplanner/form-builder'
import {
  EntityInterfaces,
  BackendService,
  FilterParam
} from '@goplanner/api-client'

import { DialogAddressComponent } from 'src/app/shared/components/dialog-address/dialog-address.component'

@Component({
  selector: 'app-formly-address',
  templateUrl: './address-field.component.html'
})
export class AdressFieldComponent extends SelectComponent implements OnInit {
  readonly destroy = inject(DestroyRef)

  search: ComponentRef<DialogAddressComponent> | undefined

  selectToAssociateTranslationKey = 'goplanner.COMMON.SELECT'

  foreign = {
    fields:
      '#pais__pais_siglas,alias_direccion,nombre,&,codigo_erp,direccion_formateada',
    table: 'ent_direcciones',
    key: 'id'
  }

  override get configItems() {
    return [
      {
        icon: 'fas fa-map-location-dot',
        action: this.openSearchAddress.bind(this)
      }
    ]
  }

  override get lazyLoad() {
    return (event: { first: number; last: number }) =>
      this.dropdown.lazyLoad(
        `ent_direcciones_${this.id}`,
        'ent_direcciones',
        this.foreign,
        this.backend,
        event
      )
  }

  override get filter() {
    return (event: { originalEvent: Event; filter: any }) =>
      this.dropdown.filter(
        `ent_direcciones_${this.id}`,
        'ent_direcciones',
        this.foreign,
        this.backend,
        event
      )
  }

  override get sort() {
    return (sort: { type: 'ASC' | 'DESC' }) => {
      this.dropdown.sort(
        `ent_direcciones_${this.id}`,
        'ent_direcciones',
        this.foreign,
        this.backend,
        sort,
        true
      )
    }
  }

  get clientField(): string | undefined {
    return this.props['clientField']
  }

  idCliente: number | undefined

  loadingObservable!: Observable<boolean>

  dropdownItems!: Observable<EntityInterfaces['ent_direcciones']['get'][]>

  filterParams: (FilterParam<'ent_direcciones'> | 'OR' | 'AND')[] = [
    {
      field: 'activo',
      operator: '=',
      value: true
    }
  ]

  constructor(
    private vcr: ViewContainerRef,
    private dropdown: DropdownService,
    private backend: BackendService
  ) {
    super()
  }

  ngOnInit() {
    this.form.valueChanges
      .pipe(takeUntilDestroyed(this.destroy))
      .subscribe((values) => {
        if (
          this.clientField &&
          this.clientField in values &&
          values[this.clientField]
        ) {
          if (this.idCliente === values[this.clientField]) return
          this.idCliente = values[this.clientField]
          this.adjustFilterParams(this.idCliente)
        } else if (this.clientField && this.clientField in values) {
          this.idCliente = undefined
          this.adjustFilterParams(this.idCliente)
        }
      })

    this.dropdown.loadingSubject[`ent_direcciones_${this.id}`] =
      new BehaviorSubject<boolean>(true)
    this.loadingObservable =
      this.dropdown.loadingSubject[`ent_direcciones_${this.id}`].asObservable()

    setTimeout(() => {
      if (this.clientField) {
        const clientValue = this.form.value[this.clientField]
        if (clientValue) {
          this.idCliente = clientValue
          this.filterParams = this.getFilterParams(this.idCliente)
        }
      }

      this.dropdownItems = this.dropdown.getOptions(
        `ent_direcciones_${this.id}`,
        'ent_direcciones',
        this.foreign,
        this.backend,
        this.filterParams,
        this.formControl?.value as number | number[],
        true
      )
    }, 0)
  }

  getFilterParams(
    idCliente: number | undefined
  ): FilterParam<'ent_direcciones'>[] {
    return [
      {
        field: 'activo',
        operator: '=',
        value: true
      },
      ...(idCliente
        ? ([
            'OR',
            {
              field: 'id_cliente',
              operator: '=',
              value: idCliente
            },
            {
              field: 'cli_direccion_entrega__id_cliente',
              operator: '=',
              value: idCliente
            },
            'AND'
          ] as FilterParam<'ent_direcciones'>[])
        : [])
    ]
  }

  adjustFilterParams(idCliente: number | undefined) {
    this.filterParams = this.getFilterParams(idCliente)
    this.dropdown.applyBaseFilter(
      `ent_direcciones_${this.id}`,
      'ent_direcciones',
      this.foreign,
      this.backend,
      this.filterParams,
      true,
      this.formControl
    )
  }

  /**
   * Open the search address dialog for the field
   * @param field the field to open the search dialog for
   * @param fieldModel the field model
   */
  openSearchAddress(): void {
    this.search = this.vcr.createComponent(DialogAddressComponent)
    const searchInstance = this.search.instance
    searchInstance.visible = true
    searchInstance.saveButtonIcon = 'pi pi-check'
    searchInstance.selectToAssociateTranslationKey =
      this.selectToAssociateTranslationKey
    searchInstance.saveButtonSeverity = 'success'
    searchInstance.valueField = 'id'
    searchInstance.value = this.formControl?.value as number | number[]
    searchInstance.baseFilter = this.filterParams
    searchInstance.excludeFilters = this.idCliente
      ? ['activo', 'id_cliente', 'cli_direccion_entrega__id_cliente']
      : ['activo']
    searchInstance.baseRecord = this.idCliente
      ? { id_cliente: this.idCliente }
      : {}

    const afterSave = searchInstance.saved
      .pipe(takeUntilDestroyed(this.destroy), debounceTime(200))
      .subscribe((selected) => {
        const value = Array.isArray(selected)
          ? selected.map((s) => s['id'])
          : selected['id']
        if (!value) return
        this.dropdown.applyValue(`ent_direcciones_${this.id}`, value, true)
        setTimeout(() => {
          this.formControl.setValue(value)
          this.options.formState = {
            ...this.options.formState,
            [this.field.key as string]: value
          }
        }, 0)
        this.search?.instance.onDestroy()
      })

    const destroy = searchInstance.destroy
      .pipe(takeUntilDestroyed(this.destroy), debounceTime(200))
      .subscribe(() => {
        afterSave?.unsubscribe()
        destroy?.unsubscribe()
        this.search?.destroy()
        this.search = undefined
      })
  }
}
