import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { FormlyModule } from '@ngx-formly/core'
import { ReactiveFormsModule } from '@angular/forms'

import { SatinelSelectModule } from '@satinel-system/core'

import { AdressFieldComponent } from './address-field.component'

@NgModule({
  declarations: [AdressFieldComponent],
  imports: [
    FormlyModule,
    CommonModule,
    ReactiveFormsModule,
    SatinelSelectModule
  ],
  exports: [AdressFieldComponent]
})
export class AdressFieldModule {}
